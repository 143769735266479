import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-405e2be6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row body-color" }
const _hoisted_2 = { class: "col-2 sidebar" }
const _hoisted_3 = { class: "d-none" }
const _hoisted_4 = { class: "col-8" }
const _hoisted_5 = { class: "body-height" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BoosterHeader = _resolveComponent("BoosterHeader")!
  const _component_BoosterNav = _resolveComponent("BoosterNav")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_BoosterFooter = _resolveComponent("BoosterFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BoosterHeader),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_BoosterNav)
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_router_view)
        ]),
        _createVNode(_component_BoosterFooter)
      ])
    ])
  ], 64))
}