import axios, { InternalAxiosRequestConfig, AxiosError } from "axios";
import { Auth0VueClient } from "@auth0/auth0-vue";

export default class RequestBuilder {
  static token = "";

  static adviserApi = axios.create({
    baseURL: process.env.VUE_APP_ADVISER_XAPI_URL,
    // have full status in the response body instead of throw exceptions
    validateStatus: () => true,
  });

  static async interceptorSetup(useAuth0: Auth0VueClient) {
    if (!RequestBuilder.token || RequestBuilder.token.length == 0) {
      RequestBuilder.token = await useAuth0.getAccessTokenSilently();
    }

    RequestBuilder.adviserApi.interceptors.request.clear();
    RequestBuilder.adviserApi.interceptors.request.use((config: InternalAxiosRequestConfig) => {
      if (RequestBuilder.token && RequestBuilder.token.length > 0) {
        config.headers["Authorization"] = `Bearer ${RequestBuilder.token}`;
      }
      return config;
    }, null);

    RequestBuilder.adviserApi.interceptors.response.clear();
    RequestBuilder.adviserApi.interceptors.response.use(
      (response) => response,
      (error: AxiosError | Error) => {
        // TODO: Popup error later.
        Promise.reject(error);
      }
    );
  }

  static async makePutRequest(url: string, requestBody: any) {
    const response = await RequestBuilder.adviserApi.put(url, requestBody);
    const responseBody = response.data;

    return {
      statusCode: response.status,
      body: responseBody,
    };
  }

  static async makePostRequest(url: string, requestBody: any = null, config?: any) {
    const response = await RequestBuilder.adviserApi.post(url, requestBody, config);
    const responseBody =
      response.status == 200 || response.status == 400 ? await response.data : null;

    return {
      statusCode: response.status,
      body: responseBody,
    };
  }

  static async makePatchRequest(url: string, requestBody: any) {
    const response = await RequestBuilder.adviserApi.patch(url, requestBody);
    const responseBody =
      response.status == 200 || response.status == 400 ? await response.data : null;

    return {
      statusCode: response.status,
      body: responseBody,
    };
  }

  static async makeGetRequest(url: string, params: Record<string, string> | null = null) {
    if (params) {
      const searchParams = new URLSearchParams(params);
      url = `${url}?${searchParams.toString()}`;
    }

    const response = await RequestBuilder.adviserApi.get(url);
    const responseBody =
      response.status == 200 || response.status == 400 ? await response.data : null;

    return {
      statusCode: response.status,
      body: responseBody,
    };
  }

  static async makeDeleteRequest(url: string, requestBody: any = null) {
    const response = await RequestBuilder.adviserApi.delete(url, requestBody);
    const responseBody = response.status == 200 ? await response.data : null;

    return {
      statusCode: response.status,
      body: responseBody,
    };
  }
}
