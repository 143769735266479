<template>
  <div class="row justify-content-end">
    <Button class="m-2" icon="pi pi-bars" />
  </div>
  <PanelMenu class="ps-5" :model="items" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Button from "primevue/button";
import PanelMenu from "primevue/panelmenu";

export default defineComponent({
  name: "BoosterNav",
  components: {
    Button,
    PanelMenu
  },
  data() {
    return {
      items: [
        { icon: 'home-icon ', label: 'Advisor hub'},
        { icon: 'pi pi-user', label: 'Clients' },
        { icon: 'pi pi-file', label: 'Tools, forms and docs' },
        { label: 'Products', icon: 'pi pi-caret-right',
          items: [{label: 'Insurance', to: '/products/insurance'}]
        }
      ]
    }
  }
});
</script>

<style lang="scss" scoped>
@import "@/scss/booster-styles.scss";

:deep(.p-panelmenu-header-link), :deep(.p-panelmenu-content) {
  background: $booster-background-gray;
  border: unset !important;
}
</style>
