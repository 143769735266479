<template>
  <BoosterHeader />
  <div class="row body-color">
    <div class="col-2 sidebar">
      <div class="d-none">
        <BoosterNav />
      </div>
    </div>
    <div class="col-8">
      <div class="body-height">
        <router-view></router-view>
      </div>
      <BoosterFooter />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BoosterHeader from "@/components/booster-header/BoosterHeader.vue";
import BoosterNav from "@/components/booster-nav/BoosterNav.vue";
import BoosterFooter from "@/components/booster-footer/BoosterFooter.vue";

export default defineComponent({
  name: "AdviserHubLayout",
  components: {
    BoosterHeader,
    BoosterNav,
    BoosterFooter
  }
});
</script>

<style lang="scss" scoped>
@import "@/scss/booster-styles.scss";

.body-height {
  min-height: 70vh;
}

.sidebar {
  background-color: $booster-background-gray;
}

.body-color {
  background-color: $booster-background-gray;
}
</style>
