import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0671937c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_PanelMenu = _resolveComponent("PanelMenu")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Button, {
        class: "m-2",
        icon: "pi pi-bars"
      })
    ]),
    _createVNode(_component_PanelMenu, {
      class: "ps-5",
      model: _ctx.items
    }, null, 8, ["model"])
  ], 64))
}